
import {rzContainer, rzFooter, rzIconVisa, rzIconMastercard, rzIconMir, rzIconPhone, rzIconMail, rzIconWhatsApp} from 'razlet-ui';

export default {
  name: 'footer-block',
  components: {
    rzFooter, 
    rzIconVisa, 
    rzIconMastercard, 
    rzIconMir,
    rzIconPhone,
    rzIconMail, 
    rzIconWhatsApp,
    rzContainer,
  },
  data() {
    return {
      year: (new Date()).getFullYear(),
      contacts: [
        /* {icon: 'whatsapp', label: 'WhatsApp', href: 'https://wa.me/79535888700'}, */
        {icon: 'phone', label: '+7 (904) 898-52-18', href: 'tel:79048985218'},
        {icon: 'email', label: 'info@catfly.ru', href: 'mailto: info@catfly.ru'},
        {icon: 'schedule', label: '08:00 - 20:00'},
      ],
      banks: ['visa', 'mir', 'maestro', 'mastercard'],
    };
  },
};
